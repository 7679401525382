@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package split.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.PluralStringResource

private object CommonMainPlurals0 {
  public val days: PluralStringResource by 
      lazy { init_days() }
}

@InternalResourceApi
internal fun _collectCommonMainPlurals0Resources(map: MutableMap<String, PluralStringResource>) {
  map.put("days", CommonMainPlurals0.days)
}

internal val Res.plurals.days: PluralStringResource
  get() = CommonMainPlurals0.days

private fun init_days(): PluralStringResource =
    org.jetbrains.compose.resources.PluralStringResource(
  "plurals:days", "days",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr", 10,
    48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr", 10,
    84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10, 48),
    )
)
